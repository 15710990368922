
export const productionQuality = {
  qualityOfService:
    "We guarantee you a good experience with these services we offer.",
};

export const softwareDev = {
  id: 1,
  title: "IT Support",
  summary:
    "We offer support services to your existing IT infrastracture. We have a dedicate team to monitor software, keep it updated and optimised for maximum productivity. ",
  image: "cloud_dev_img.svg",
  showBtn: true,
};

export const webHosting = {
  id: 3,
  title: "Web Hosting",
  summary:
    "Our web hosting platform has flexible options to suit your business. Small to medium enterprises find them affordable. Our packages come with unlimited email hosting services. ",
  image: "web_hosting_img.svg",
  showBtn: true,
};

export const mobileAppDev = {
  id: 2,
  title: "Mobile App Development",
  summary:
    "Mobile applications are the way to go nowadays. Our mobile application development lab ensures quality apps for your need. We develop for both IoS and Android.",
  image: "mobile_dev_img.svg",
  showBtn: true,
};

export const vrDev = {
  id: 4,
  title: "Mixed Reality Development",
  summary:
    "Mixed reality is the next BIG thing. We have you covered. For your virtual and augmented reality applications we guarantee you the best. ",
  image: "vr_dev_img.svg",
  showBtn: true,
};

export const cloudDev = {
  id: 1,
  title: "Custom Software Development",
  summary:
    "We develop custom software for your needs. We ensure scalability and ease of use of your product. We will work with you at every development cycle. ",
  image: "custom_dev_img.svg",
  showBtn: true,
};

export const webDev = {
  id: 3,
  title: "Web Development",
  summary:
    "We develop quality websites that are optimised for all platforms. Our team guarantees seamless websites for both web and mobile platforms for a great user experience. All round support services guaranteed.",
  image: "custom_dev_img.svg",
  showBtn: true,
};
