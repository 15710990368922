import React from "react";

import {
  makeStyles,
  Container,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    backgroundColor: "white",
    padding: "2rem",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.25)",
    borderTop: "2px solid #3a8de5ff",
  },
  freshForm: {
    padding: "20px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  field: {
    marginBottom: 20,
    marginTop: 20,
    display: "block",
    color: "#3a8de5ff",
  },

  freshButton: {
    border: 0,
    backgroundColor: "#3a8de5ff",
    display: "inline-block",
    cursor: "pointer",
    color: "#ffffff",
    fontSize: "15px",
    padding: "10px 20px",
    marginRight: "1rem",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
}));

const requestSubject = ["Software development", "Web hosting", "Other"];

const onSubmit = (data) => {
  data.preventDefault();
  console.log(data);
};

function ServiceRequestForm() {
  const classes = useStyles();

  return (
    <div className={classes.container} xs={12} sm={6} md={2}>
      <Typography
        variant="h4"
        align="center"
        color="textPrimary"
        gutterBottom
        style={{ paddingTop: 20, color: "#3a8de5ff" }}
      >
        Send us your request
      </Typography>
      <Container className={classes.freshForm}>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <TextField
            fullWidth
            className={classes.field}
            label="First name"
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            className={classes.field}
            label="Last name"
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            className={classes.field}
            label="Email"
            variant="outlined"
            type="email"
            required
          />
          {/* <InputLabel id="label">Request type</InputLabel>
          <Select labelId="label" id="select" value={false} required>
            <MenuItem value="">
              <em>Please select a request</em>
            </MenuItem>
            {requestSubject.map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select> */}
          <TextField
            fullWidth
            className={classes.field}
            multiline
            rows={5}
            label="Request details"
            variant="outlined"
            required
          />
          <Button
            className={classes.freshButton}
            type="submit"
            variant="contained"
            onClick={() => {
              console.log("Clicked");
            }}
          >
            Submit
          </Button>
        </form>
      </Container>
    </div>
  );
}
export default ServiceRequestForm;
