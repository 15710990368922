import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  callToAction: {
    flexGrow: 1,
    backgroundColor: "#ececec",
    padding: theme.spacing(8, 0, 8),
    color: "#3a8de5ff",
  },
  callToActionHeader: {
    color: "#3a8de5ff",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  callToActionText: {
    color: "#5773a8",//"#3a8de5ff",
    [theme.breakpoints.down("sm")]: {},
  },
  btnContained: {
    backgroundColor: "#5773a8",//"#3a8de5ff",
    color: "white",
    '&:hover': {
      backgroundColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
  btnOutlined: {
    borderColor: "#5773a8",//"#3a8de5ff",
    color: "#5773a8",//"#3a8de5ff",
    '&:hover': {
      borderColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
}));

const CallToAction = () => {
  const classes = useStyles();

  return (
    <div className={classes.callToAction}>
      <Container xs={12} sm={12} md={12}>
        <Typography
          align="center"
          variant="h4"
          className={classes.callToActionHeader}
          gutterBottom
        >
          {"Ready to work with us?"}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className={classes.callToActionText}
          paragraph
        >
          {"Sign up for hosting or send us your request."}
        </Typography>
        <Grid container spacing={2} justify="center">
          <Grid item>
            
            <Button variant="contained" className={classes.btnContained} to='/services'>
              Start hosting
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" className={classes.btnOutlined}>
              Contact us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CallToAction;
