import React from "react";
import Header from "../Generic/Header";
import {
  sdeServiceData,
  mobileServiceData,
  webServiceData,
  vrServiceData,
} from "./ServiceData";
import ServiceRequestForm from "./ServiceRequestForm";
import ServiceBanner from "./ServiceBanner";

const Service = (props) => {
  const { id } = props.match.params;
  const serviceRequested =
    id == sdeServiceData.id
      ? sdeServiceData
      : id == mobileServiceData.id
      ? mobileServiceData
      : id == webServiceData.id
      ? webServiceData
      : id == vrServiceData.id
      ? vrServiceData
      : null;
  return (
    <>
      <Header {...serviceRequested} />
      <ServiceBanner {...serviceRequested} />
      <ServiceRequestForm />
    </>
  );
};

export default Service;
