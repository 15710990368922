import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo3 from "../../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  BackdropProps: {
    background: "transparent",
  },
  myLinks: {
    textDecoration: "none",
  },
}));

const NavDrawer = ({ toggleNavDrawer, drawerOpen, links }) => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Drawer
          open={drawerOpen}
          onClick={toggleNavDrawer}
          style={{ display: "flex", flexGrow: 1, width: "60%" }}
        >
          <div
            style={{
              align: "center",
              padding: 30,
            }}
          >
            <List>
              <ListItem
                component={Link}
                to="/"
                onClick={toggleNavDrawer}
                style={{
                  marginBottom: 10,
                }}
              >
                <ListItemIcon>
                  <img src={logo3} alt="Logo" width="160" />
                </ListItemIcon>
              </ListItem>
              {links.map((item) => (
                <div><ListItem
                  button
                  component={Link}
                  to={item.to}
                  style={{
                    marginBottom: 0,
                  }}
                  onClick={toggleNavDrawer}
                >
                  <ListItemText className={classes.myLinks}>
                    {item.link}
                  </ListItemText>
                 
                </ListItem>
                  <hr></hr>
                </div>
                
              ))}
            </List>
            <div style={{paddingTop:30, marginRight:"auto", marginLeft:"auto"}}>
                <Typography>  &copy; Kaithero {1900 + new Date().getYear()}<br></br> All rights reserved</Typography>
                </div>
            {/* <div
              style={{
                align: "center",
                paddingBottom: 0,
                paddingTop: "100%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <Typography>
                &copy; Kaithero {1900 + new Date().getYear()}
                <br></br> All rights reserved
              </Typography>
            </div> */}
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default NavDrawer;
