import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography, Icon } from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  myLink: {
    color: "white",
    textDecoration: "none",
    fontFamily: "Sora",
    fontWeight: "200",
    fontSize: "12px",
    textAlign: "left",
    marginBottom: 12,
  },
  linkText: {
    color: "#fff",
    fontFamily: "Sora",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 12,
    
    
  },
  myLink: {
    textDecoration: "none",
    '&:hover': {
     color: 'grey',
     // color: '#3c52b2',
  },
  }
}));

const FooterDesktop = ({ company, services, email, phone }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyItems="center" alignContent="center">
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}>
       <Link to="/" className={classes.myLink}> <Typography variant="subtitle1"  className={classes.linkText} style={{fontWeight:600}}>{company}</Typography></Link>
        <Icon>
          <FacebookIcon />
        </Icon>
        <Icon>
          <LinkedInIcon />
        </Icon>
        <Icon>
          <TwitterIcon />
        </Icon>
      </Grid>

      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}>
        <Typography variant="subtitle1" style={{fontWeight:600}}>Services</Typography>
        <Link to="" className={classes.myLink}> <Typography className={classes.linkText}>Web hosting</Typography> </Link>
        <Link to="" className={classes.myLink}>  <Typography className={classes.linkText}>
          Software development
        </Typography>
        </Link>
        <Link to="" className={classes.myLink}><Typography className={classes.linkText}>Mobile application</Typography></Link>
      </Grid>

      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}>
        <Typography variant="subtitle1" style={{fontWeight:600}}>Company</Typography>
        <Link to="" className={classes.myLink}> <Typography align="left" className={classes.linkText}>
          About Us
        </Typography>
          </Link>
      
        <Typography align="left"></Typography>
        <Typography align="left"></Typography>
      </Grid>

      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}>
        <Typography variant="subtitle1" style={{fontWeight:600}}>Contact</Typography>
        <Typography align="left" className={classes.linkText}>
          {email}
        </Typography>
        <Typography align="left" className={classes.linkText}>
          {phone}
        </Typography>
        <Typography align="left"></Typography>
      </Grid>
    </Grid>
  );
};

export default FooterDesktop;
