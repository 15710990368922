import {
  Button,
  Container,
  createMuiTheme,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ProductBanner from "./ProductBanner";
import {
  productionQuality,
  mobileAppDev,
  softwareDev,
  webHosting,
  vrDev,
  cloudDev,
  webDev,
} from "./ProductsData";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
  },
  product: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0,3),
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight:"5%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 6),
    },
  },
  productTitle: {
    color: "#4d4d4dff",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "1.4px",
    textTransform: "uppercase",
    marginBottom: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      letterSpacing: "1.4px",
      lineHeight: "18px",
    },
  },
  myText: {
    fontSize: "10px",

  },
  learnMoreBtn: {
    backgroundColor: '#5773a8',//"#3a8de5ff",
    color: "white",
    '&:hover': {
      backgroundColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
  sectionMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const theme = createMuiTheme();
theme.typography.h4 = {
  color: "#4d4d4dff",
  fontSize: 36,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};


const ProductCenter = (props) => {
  const classes = useStyles();
  const image = require(`../../assets/${props.image}`);

  return (
    <>
      <div className={classes.product}>
      <Grid container>
        <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            
        <Grid container spacing={2}>
         <Grid item xs={12} sm={4} >
                <ProductBanner {...webHosting}/>
          </Grid>
              
          <Grid item xs={12} sm={4} >
                <ProductBanner {...webDev}/>
              </Grid>
              
              <Grid item xs={12} sm={4} >
                <ProductBanner {...mobileAppDev}/>
              </Grid>
              
            </Grid>
            <Grid container spacing={2}>
         <Grid item xs={12} sm={4} >
                <ProductBanner {...cloudDev}/>
          </Grid>
              
          <Grid item xs={12} sm={4} >
                <ProductBanner {...vrDev}/>
              </Grid>
              
              <Grid item xs={12} sm={4} >
                <ProductBanner {...softwareDev}/>
          </Grid>
        </Grid>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
     </Grid>
        
      </div>
    </>
  );
};

export default ProductCenter;
