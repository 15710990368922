import React from "react";
import {
  Button,
  Container,
  createMuiTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ececec",
    padding: theme.spacing(8, 0, 8),
  },
  sectionMobile: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
}));

const theme = createMuiTheme();
theme.typography.h3 = {
  color: "#4d4d4dff",
  fontSize: 48,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 36,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

const AboutUs = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <div>
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Who are we?
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            {props.history}
          </Typography>
        </div>
        <div>
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
            style={{ paddingTop: 30 }}
          >
            Our vision
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            {props.vision}
          </Typography>
        </div>
        <div>
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
            style={{ paddingTop: 30 }}
          >
            Our mission
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            {props.mission}
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
