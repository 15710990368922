export const AboutUsData = {
  type: "service",
  header: "It is All About You",
  summary:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  button_text: "Try our solutions",
  history:
    "We are robust IT company with a strong software development background. We offer IT services to individuals and companies.",
  vision:
  "To support the digital revolution and leave anyone behind",
  mission:
    "To transform the sector."
};
