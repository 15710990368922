import React from "react";
import AboutUs from "./About";
import Header from "../Generic/Header";
import { AboutUsData } from "./AboutData";
import ServiceRequestForm from "../Service/ServiceRequestForm";

const About = () => {
  return (
    <div>
      <Header {...AboutUsData} />
      <AboutUs {...AboutUsData} />
      <ServiceRequestForm />
    </div>
  );
};

export default About;
