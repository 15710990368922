import React from "react";
import {
  Button,
  Container,
  createMuiTheme,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Typewriter from "typewriter-effect";
import { ArrowForward } from "@material-ui/icons";
import hero from "../../assets/hero_img_1.svg";
import { heroSectionData } from "./HeroData";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  product: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 4),
    },
  },
  typewritertext: {
    color: "#3a8de5ff",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "1.4px",
    textTransform: "uppercase",
    marginBottom: "16px",
  },
  heroBtn: {
    backgroundColor: '#5773a8',//"#3a8de5ff",
    color: "white",
    '&:hover': {
      backgroundColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
  sectionMobile: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
}));

const theme = createMuiTheme();
theme.typography.h4 = {
  color: "#4d4d4dff",
  fontSize: 36,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

const ProductBannerLeft = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.product}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <Container
              maxWidth="sm"
              style={{
                paddingLeft: "5%",
                paddingTop: "2%",
                paddingBottom: "10%",
              }}
            >
            
              <div>
                <Typography
                  className={classes.productTitle}
                  variant="h3"
                  gutterBottom
                >
                  {heroSectionData.heroHeader}
                </Typography>
              </div>
              <div className={classes.typewritertext}>
                <Typewriter
                  options={{
                    strings: [...heroSectionData.typwWriterStrings],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            
              <Typography
                variant="h6"
                align="left"
                color="textSecondary"
                paragraph
              >
                {heroSectionData.heroSubtitle}
              </Typography>
              <Button variant="contained" className={classes.heroBtn}>
                {heroSectionData.heroBtnText} <ArrowForward />
              </Button>
            </Container>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Hidden smDown implementation="js">
              <img src={hero} alt="Hero" width="90%" align="left" />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={5} align="center">
            <Hidden mdUp implementation="js">
              <img src={hero} alt="Hero" width="100%" align="center"></img>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProductBannerLeft;
