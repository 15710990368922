export const sdeServiceData = {
  id: 1,
  type: "service",
  header: "Just imagine it, we will build it",
  summary:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  button_text: "Send us you request",
  services: [
    {
      id: 1,
      title: "Legacy Software Support",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except AntarcticaLizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "legacy_system_img.svg",
    },
    {
      id: 2,
      title: "Software Development",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "custom_dev_img.svg",
    },
    {
      id: 3,
      title: "Dev Ops",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "dev_ops_img.svg",
    },
    {
      id: 4,
      title: "Cloud software development",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "cloud_dev_img.svg",
    },
  ],
};

export const mobileServiceData = {
  id: 2,
  type: "service",
  header: "Just imagine it, we will build it",
  summary:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  button_text: "Send us you request",
  services: [
    {
      id: 1,
      title: "Native Applications",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "mobile_native_dev_img.svg",
      showBtn: false,
    },
    {
      id: 2,
      title: "Cross Platform Applications",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "mobile_cross_dev_img.svg",
      showBtn: false,
    },
  ],
};

export const webServiceData = {
  id: 3,
  type: "service",
  header: "For Affordable Web Hosting and Development",
  summary:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  button_text: "Start hosting with us",
  services: [
    {
      id: 1,
      title: "Web hosting",
      summary:
    "Our hosting platform is robust to support your needs. We have different flexible packages for web hosting with incredible add-on. Kaithost is our hosting platform.",
        image: "dev_ops_img.svg",
      showBtn: false,
    },
    {
      id: 2,
      title: "Web application Development",
      summary:
        "We develop website that are user friendly and simple to use. We engage you at every step of our development cycle to make sure the website is what you want. ",
      image: "web_dev_img.svg",
      showBtn: false,
    },
  ],
};

export const vrServiceData = {
  id: 4,
  type: "service",
  header: "Innovative Mixed Reality Solutions",
  summary:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  button_text: "Send us you request",
  services: [
    {
      id: 1,
      title: "Augmented Reality",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "vr_img.png",
      showBtn: false,
    },
    {
      id: 2,
      title: "Virtual Reality",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
      image: "vr_dev_img.svg",
      showBtn: false,
    },
  ],
};
