import React from "react";
import { AppBar, Button, Hidden, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/logo.svg";
import { navBarData } from "./Data";
import NavDrawer from "./NavDrawer";
import { Link } from "react-router-dom";
import logo3 from "../../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifySelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#333333",//"#4d4d4dff",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2, 2),
      paddingLeft: "10%",
      paddingRight: "10%",
      height: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0),
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginRight: 10,
    },
  },
  getInTouchBtn: {
    color: "white",
    backgroundColor: "#5773a8",//"#3a8de5ff",
    '&:hover': {
      backgroundColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
}));

const NavBar = () => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleNavDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleNavDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Hidden mdUp implementation="js">
            <div style={{ flexGrow: 1 }}>
              <Link to="/">
                <img src={logo3} alt="Logo" align="left" width="120" />
              </Link>
            </div>
          </Hidden>
          <Hidden smDown implementation="js">
            <div style={{ flexGrow: 1 }}>
              <Link to="/">
                <img src={logo3} alt="Logo" align="left" width="200" />
              </Link>
            </div>
          </Hidden>
          <div className={classes.sectionDesktop}>
            {navBarData.links.map((item) => (
              <Button color="inherit" component={Link} to={item.to} style={{ fontWeight:'bold', marginLeft:'20px'}}>
                {item.link}
              </Button>
            ))}
          </div>
          <Button edge="end" className={classes.getInTouchBtn}>
            {navBarData.callToActionBtnLink}
          </Button>
        </Toolbar>
      </AppBar>
      <Hidden>
        <NavDrawer
          toggleNavDrawer={toggleNavDrawer}
          drawerOpen={drawerOpen}
          {...navBarData}
        />
      </Hidden>
    </div>
  );
};

export default NavBar;
