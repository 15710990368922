import React from "react";
import {
  Button,
  Container,
  createMuiTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 8),
  },
  headerBtn: {
    backgroundColor: "#3a8de5ff",
    color: "white",
  },
  sectionMobile: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
}));

const theme = createMuiTheme();
theme.typography.h3 = {
  color: "#4d4d4dff",
  fontSize: 48,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 36,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

const Header = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <Typography
          variant="h3"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {props.header}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {props.summary}
        </Typography>
        <div align="center">
          <Button variant="contained" className={classes.headerBtn}>
            {props.button_text} <ArrowForward />
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Header;
