import {
  Button,
  Container,
  createMuiTheme,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: "10%",
      paddingBottom: "10%",
    },
  },
  product: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 0),
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight:"5%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  productTitle: {
    color: "#4d4d4dff",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "1.4px",
    textTransform: "uppercase",
    marginBottom: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      letterSpacing: "1.4px",
      lineHeight: "18px",
    },
  },
  myText: {
    fontSize: "18px",

  },
  learnMoreBtn: {
    backgroundColor: '#5773a8',//"#3a8de5ff",
    color: "white",
    '&:hover': {
      backgroundColor: '#5c85d6',
     // color: '#3c52b2',
  },
  },
  sectionMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  myImage: {
    align:"center",
  }
}));

const theme = createMuiTheme();
theme.typography.h4 = {
  color: "#4d4d4dff",
  fontSize: 36,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

const ProductBanner = (props) => {
  const classes = useStyles();
  const image = require(`../../assets/${props.image}`);

  return (
    <>
      <div className={classes.product}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}><div><img src={image.default} alt="Hero" width="150px" height="160px" style={{paddingLeft:0}}/></div></Grid>
          
          <Grid item xs={12}> <div>
            <Typography
              align="center"
                  className={classes.productTitle}
                  variant="h5"
                  gutterBottom
                >
                  {props.title}
                </Typography>
          </div>
          </Grid>
          <Grid item xs={12}>
              <Typography
              variant="h7"
              align="center"
              color="textSecondary"
              /* align="center" */
              paragraph
              className={classes.myText}
              >
                {props.summary}
            </Typography>
            </Grid>
              {props.showBtn === true && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.learnMoreBtn}
                  component={Link}
                  to={`services/${props.id}`}
                >
                  Learn more
                </Button>
                </Grid>
              )}
              
    
        
        
                   
      </Grid>
      </div>
    </>
  );
};

export default ProductBanner;
