import React from "react";
import CallToAction from "../components/CallToAction";
import HeroSection from "../components/HeroSection";
import Products from "../components/Products";
import WhyBanner from "../components/WhyBanner";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <WhyBanner />
      <Products />
      <CallToAction />
    </div>
  );
};

export default Home;
