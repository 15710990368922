export const footerData = {
  company: "Kaithero",
  services: [
    "Web hosting",
    "Software development",
    "Mixed reality development",
  ],
  email: "info@kaithero.com",
  phone: "+(263) 488 392",
  facebook: "",
  linkedIn: "",
  twitter: "",
};
