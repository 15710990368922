import {
  Button,
  Container,
  createMuiTheme,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import slowDown from "../../assets/why_img.svg";
import { whyBannerData } from "./WhyBannerData";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  product: {
    flexGrow: 1,
    backgroundColor: "#052c4dff",
    padding: theme.spacing(6, 2, 6),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  why: {
    color: "#e6e6e6ff",
  },
  whyTitle: {
    color: "#3a8de5ff",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "1.4px",
    textTransform: "uppercase",
    marginBottom: "16px",
  },
  whyHeader: {
    color: "#e6e6e6ff",
    fontSize: 36,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
    },
  },
}));

const theme = createMuiTheme();
theme.typography.h4 = {
  color: "#4d4d4dff",
  fontSize: 36,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
};

theme.typography.h6 = {
  color: "#4d4d4dff",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
};

const WhyBanner = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.product}>
        <Grid container>
          <Grid item xs={12} sm={5} align="right">
            <Hidden smDown implementation="js">
              <img src={slowDown} alt="Hero" width="40%" />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container
              maxWidth="sm"
              style={{ paddingTop: "5%", paddingBottom: "5%" }}
            >
              <div>
                <Typography
                  className={classes.whyTitle}
                  variant="h3"
                  gutterBottom
                >
                  {whyBannerData.title}
                </Typography>
              </div>
              <Typography
                variant="h5"
                align="justify"
               // color="textSecondary"
                paragraph
                style={{color:"#e0e0eb"}}
              >
                {whyBannerData.headers}
              </Typography>
              <Typography
                variant="h6"
                align="justify"
                color="textSecondary"
                className={classes.why}
                paragraph
              >
                {whyBannerData.reasons}
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} sm={5} align="center">
            <Hidden mdUp implementation="js">
              <img src={slowDown} alt="Hero" width="100%" align="center" />
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default WhyBanner;
