import React from "react";
import logo from "./logo.svg";
import "./App.css";
// import Home from "./pages/Home";
// import About from "./pages/About";
// import Services from "./pages/Services";
// import SingleService from "./pages/SingleService";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

import Home from "./pages";
import Navbar from "./components/Navbar";
import Service from "./components/Service";
import About from "./components/About";
import Footer from "./components/RealFooter/";
import Products from "./components/Products";

function App() {
  const myhistory = createBrowserHistory({
    /* basename: '/portal'  */
  });

  return (
    <Router history={myhistory}>
      {/*  Wilfred's code  */}
      <CssBaseline />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/services" exact component={Products} />
        <Route path="/about" exact component={About} />
        <Route
          path="/services/:id"
          exact
          render={(props) => <Service {...props} />}
        />
      </Switch>
      <Footer />

      {/* OLD code */}
      {/* <div className="App">
        <Route path="/" exact component={Home} />
        <Route path="/services" exact component={Services} />
        <Route path="/about" exact component={About} />
        <Route
          path="/services/:id"
          exact
          render={(props) => <SingleService {...props} />}
        />
      </div> */}
    </Router>
  );
}

export default App;
