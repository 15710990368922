export const heroSectionData = {
  heroHeader: "Innovative Solutions for Everyday Problems",
  heroSubtitle:
    "We are here to change the traditional ways, let us help you go  digital with our solutions.",
  heroBtnText: "Try our solutions",
  heroImg: "../../assets/hero_img_1.svg",
  typwWriterStrings: [
    "software development",
    "web hosting",
    "web and mobile applications",
    "virtual reality",
    "augmented reality",
  ],
};
