import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import ProductBannerLeft from "../Products/ProductBannerLeft";
import ProductBannerRight from "../Products/ProductBannerRight";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ececec",
  },
  cardGrid: {
    backgroundColor: "#ececec",
    padding: theme.spacing(2, 2, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 2),
      fontSize: 18,
    },
  },
  card: {
    height: "100%",
    width: "100",
    borderRadius: 0,
  },
  cardMedia: {
    padding: 100,
    height: 0,
    paddingTop: "56.25%", // 16:9,
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const ServiceBanner = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.services.map((service) => (
        <div>
          {service.id % 2 === 0 ? (
            <ProductBannerLeft {...service} />
          ) : (
            <ProductBannerRight {...service} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceBanner;
