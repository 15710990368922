export const navBarData = {
  callToActionBtnLink: "Get in touch",
  links: [
    {
      link: "Home",
      to: "/",
    },
    {
      link: "Services",
      to: "/services",
    },
    {
      link: "About",
      to: "/about",
    },
  ],
};
