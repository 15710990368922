import React from "react";
import ProductBannerRight from "./ProductBannerRight";
import ProductBannerLeft from "./ProductBannerLeft";
import ProductHeader from "./ProductHeader";
import ProductCenter from "./ProductCenter";
import {
  productionQuality,
  mobileAppDev,
  softwareDev,
  webHosting,
  vrDev,
  cloudDev,
} from "./ProductsData";
import { Grid } from "@material-ui/core";

const Products = () => {
  return (
    <div id="services">
   
      <ProductHeader {...productionQuality} />
      <ProductCenter {...webHosting}/> 
      {/* <ProductBannerRight {...webHosting} />
      <ProductBannerLeft {...softwareDev} />
      <ProductBannerRight {...cloudDev} />
      <ProductBannerLeft {...mobileAppDev}  />
      <ProductBannerRight {...vrDev} /> */}
    
      
      
    </div>
  );
};

export default Products;
